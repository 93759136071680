import React from 'react';
import './LocationPopup.css';

const LocationPopup = ({ isOpen, districts, searchQuery, onSearchChange, onClose, onSelectLocation }) => {
    if (!isOpen) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <div className="popup-header">
                    <h3>Select Location</h3>
                    <button className="close-button2" onClick={onClose}>X</button>
                </div>
                <div className="popup-body">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={onSearchChange}
                        placeholder="Search location..."
                        className="search-input"
                    />
                    <ul className="location-list">
                        {districts.map((district) => (
                            <li
                                key={district.id}
                                className="location-item"
                                onClick={() => onSelectLocation(district.district_name,district.id)}
                            >
                                {district.district_name}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default LocationPopup;
