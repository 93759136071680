import React, { useState, useEffect } from "react";
import './style.css';
import Autocomplete from './Autocomplete';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from './TranslationContext';
import LocationPopup from './LocationPopup'; // Import the LocationPopup component
import RightSideMenu from './RightSideMenu'; // Import the RightSideMenu component
import axios from "axios";

const Header_home = () => {
    const [isLocationPopupOpen, setIsLocationPopupOpen] = useState(false);
    const [districts, setDistricts] = useState([]); // To store all districts
    const [filteredDistricts, setFilteredDistricts] = useState([]); // To store filtered districts
    const [searchQuery, setSearchQuery] = useState(""); // Search query
    const [selectedLocation, setSelectedLocation] = useState(localStorage.getItem("citys") || "Select Location");

    const [isMenuOpen, setIsMenuOpen] = useState(false); // State for right-side menu
    const navigate = useNavigate();
    const { translate, setLanguage } = useTranslation();

    const handleLocationClick = () => {
        setIsLocationPopupOpen(true);
    };

    const handleLocationSelect = (location,id) => {
        setSelectedLocation(location);
        localStorage.setItem('city', id);
        localStorage.setItem('citys', location);


        setIsLocationPopupOpen(false);
    };

    const handleDivClick = () => {
        navigate('/Home');
    };
    const handleDivClick2 = () => {
        navigate('/Movieslist');
    };
    const handleDivClick1 = () => {
        localStorage.setItem("type_event", "event");
        navigate('/Eventslist');
    };
    const handleDivClick3 = () => {
        localStorage.setItem("type_event", "sport");
        navigate('/Eventslistsport');
    };

    const handlelogout = () => {
        localStorage.clear();
        navigate('/Login');
    };

    const handleprofile = () => {
        setIsMenuOpen(!isMenuOpen); // Toggle menu visibility
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        const filtered = districts.filter((district) =>
            district.district_name.toLowerCase().includes(query)
        );
        setFilteredDistricts(filtered);
    };

    useEffect(() => {
        const fetchDistricts = async () => {
            try {
                const api_url = localStorage.getItem('api_url');

                const response = await axios.get(api_url + '/getcities');
                setDistricts(response.data.districts);
                setFilteredDistricts(response.data.districts); // Initialize filtered list
            } catch (error) {
                console.error("Error fetching districts:", error);
            }
        };
        fetchDistricts();
    }, []);

    return (
        <div className="header">
            <div className="row">
                <div className='col-md-3'>
                    <img
                        alt="Logo"
                        title="Logo"
                        src={require('./img/LogoT.png')}
                        onClick={handleDivClick}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
                <div className='col-md-2'>
                    <p className="trackorder" onClick={handleDivClick2}>Movies</p>
                </div>
                <div className='col-md-2'>
                    <p className="trackorder" onClick={handleDivClick1}>Events</p>
                </div>
                <div className='col-md-2'>
                    <p className="trackorder" onClick={handleDivClick3}>Sports</p>
                </div>
                <div className='col-md-1'>
                    <select
                        className='country_name'
                        value={selectedLocation}
                        readOnly
                        onClick={handleLocationClick}
                        style={{ cursor: 'pointer' }}
                    >
                        <option>{selectedLocation || "Select Location"}</option>
                    </select>
                </div>
                <div className='col-md-1'>
                    <img
                        className="vector1"
                        alt="Profile"
                        title="Profile"
                        onClick={handleprofile}
                        src={require('./img/Vector1.png')}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
                <div className='col-md-1'>
                    <img
                        className="logout"
                        alt="Logout"
                        title="Logout"
                        src={require('./img/logout.png')}
                        onClick={handlelogout}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            </div>
            <LocationPopup
                isOpen={isLocationPopupOpen}
                districts={filteredDistricts}
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
                onClose={() => setIsLocationPopupOpen(false)}
                onSelectLocation={handleLocationSelect}
            />
            <RightSideMenu isOpen={isMenuOpen} onClose={closeMenu} />
        </div>
    );
};

export default Header_home;
